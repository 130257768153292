@import '../../utils.scss';

.section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px 0;
  background-color: #f2f5f8;
  @include media('>=medium') {
    display: none;
  }
  .container {
    max-width: 1140px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    @include media('<large') {
      max-width: 960px;
    }
    @include media('<medium') {
      max-width: calc(100% - 64px);
    }
    @include media('<small') {
      max-width: calc(100% - 64px);
      padding: 0 30px;
    }
    .content {
      text-align: center;
      opacity: 0;
      transition: 1.25s;
      transition-timing-function: ease-in;
      h2 {
        font-size: 2.13em;
        line-height: 1.625;
        margin: 0 0 10px;
        letter-spacing: -0.3px;
        color: #131517;
        font-weight: 600;
      }
      p {
        line-height: 1.75;
        color: #7c7c7c;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0;
        margin-bottom: 60px;
      }
      &.animation {
        opacity: 1;
      }
    }
    .carousel {
      width: calc(100% - 48px);
      color: rgba(0, 0, 0, 0.6);
      .clients-logo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 30px;
          margin: 0 auto;
        }
      }
      .left-arrow {
        img {
          width: 13px;
          height: 32px;
        }
      }
      .right-arrow {
        img {
          width: 13px;
          height: 32px;
        }
      }
    }
  }
}
