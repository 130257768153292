@import '../../utils.scss';

.section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0 60px 0;
  @include media('>medium') {
    padding: 80px 0 40px 0;
  }
  .container {
    width: 100%;
    height: 100%;
    max-width: 1140px;
    display: flex;
    align-items: center;

    @include media('<large') {
      max-width: 960px;
    }
    @include media('<medium') {
      max-width: calc(100% - 64px);
    }
    @include media('<small') {
      max-width: calc(100% - 32px);
    }
    .top-content {
      width: 100%;
      @include media('>medium') {
        display: flex;
      }
      .image-container {
        width: 100%;
        margin-bottom: 30px;
        img {
          width: 100%;
          @include media('<small') {
            margin-top: 16px;
          }
        }
        .background {
          position: absolute;
        }
        @include media('>medium') {
          transform: translateX(100%);
          transition: 1s;
          transition-timing-function: ease-in;
        }
      }
      @include media('>medium') {
        .image-container.animation {
          transform: translateX(0);
        }
      }
      .text-container {
        @include media('>medium') {
          padding: 0 25px;
          flex: 0 0 50%;
          max-width: 55%;
          margin: 30px 0 50px 0px;
          transform: translateX(-100%);
          transition: ease-in 1s;
        }
        .text {
          h2 {
            font-size: 2.13em;
            line-height: 1.625;
            font-weight: 300;
            margin: 0 0 10px;
            letter-spacing: -0.3px;
            color: #131517;
            @include media('>medium') {
              font-size: 5rem;
              line-height: 1.25;
              font-weight: 200;
            }
          }
          p {
            color: #7c7c7c;
            font-size: 15px;
            font-weight: 400;
            letter-spacing: 0;
            font-size: 14px;
            line-height: 1.75;
            @include media('>medium') {
              font-size: 1.25rem;
              font-weight: 200;
            }
          }
        }
        .counter-container {
          display: grid;
          transform: translateX(-100%) scaleX(0);
          grid-template-columns: auto auto auto;
          @include media('>medium') {
            display: flex;
          }
          .counter-item {
            @include media('>medium') {
              margin-right: 40px;
              font-size: 1.25rem;
              font-weight: 200;
            }
            h1 {
              margin-bottom: 8px;
              font-weight: 400;
              @include media('>medium') {
                font-size: 4rem;
                color: rgba(0, 0, 0, 0.8);
                margin-bottom: 16px;
              }
            }
            p {
              font-size: 10px;
              @include media('>medium') {
                font-size: 1rem;
                letter-spacing: 0.05em;
                color: #000000;
                font-weight: bold;
              }
            }
          }
        }
        .counter-container.animation {
          transform: translateX(0px) scaleX(1);
          transition: 1s;
          transition-timing-function: ease-in;
        }
      }
      @include media('>medium') {
        .text-container.animation {
          transform: translateX(0);
        }
      }
    }
  }
}
