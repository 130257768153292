@import '../../utils.scss';

footer {
  display: flex;
  justify-content: center;
  background-color: black;
  .container {
    width: 100%;
    max-width: 1140px;
    height: auto;
    @include media('<large') {
      max-width: 960px;
    }
    @include media('<medium') {
      max-width: calc(100% - 64px);
    }
    @include media('<small') {
      max-width: calc(100% - 32px);
    }
    padding: 70px 0;
    font-size: 14px;
    .items {
      img {
        height: 20px;
      }
      p {
        color: #7c7c7c;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0;
      }
      ul.social-media {
        margin-top: 40px;
        padding: 0;
        display: flex;
        flex-direction: row;
        li {
          margin-right: 15px;
          img {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
}
