html,
body {
  margin: 0;
  padding: 0;
}

.container {
  overflow-x: hidden;
  height: 100vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
}
.body {
  height: 100vh;
  position: relative;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
