@import '../../utils.scss';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 0.3;
  }
  60% {
    opacity: 0.5;
  }
  80% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

.section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
  background-color: #2c2c2c;
  @include media('<medium') {
    display: none;
  }
  .container {
    max-width: 992px;
    .top-content {
      width: 100%;
      margin-bottom: 60px;
      opacity: 0;
      transition: 1.25s;
      transition-timing-function: ease-in;
      h2 {
        font-size: 5em;
        line-height: 1.25;
        font-weight: 300;
        margin: 0 0 10px;
        letter-spacing: -0.3px;
        color: #ffffff;
      }
      p {
        color: #ffffff;
        font-size: 1.25rem;
        font-weight: 200;
        letter-spacing: 0;
        line-height: 1.75;
        box-sizing: border-box;
      }
    }
    @include media('>medium') {
      .top-content.animation {
        opacity: 1;
      }
    }
    .contents {
      display: flex;
      .service {
        flex: 1;
        transform: translateX(-200%);
        transition: 1s;
        transition-timing-function: ease-in;
        .service-content {
          width: 100%;
          position: relative;
          .service-circle {
            position: absolute;
            width: 21rem;
            height: 21rem;
            text-align: center;
            border: 2px solid rgba(255, 255, 255, 0.7);
            border-radius: 100%;
            padding: 0;
            margin: 2rem 0;
            z-index: 2;
            list-style: none;
            li {
              width: 100%;
              text-align: right;
              position: absolute;
              z-index: 1;
              top: 50%;
              transform: translate(0%, -50%);
              button {
                font-size: 0;
                margin: -0.5rem;
                padding: 0;
                width: 1rem;
                height: 1rem;
                box-sizing: border-box;
                transition: all 0.4s ease-in-out;
                border-radius: 100%;
                border: 0.5rem solid rgba(255, 255, 255, 1);
                background-color: #fff;
                position: absolute;
                appearance: button;
                z-index: 2;
                cursor: pointer;
                outline: none;
                &:hover {
                  width: 1.5rem;
                  height: 1.5rem;
                  margin: -0.75rem;
                }
              }
              button.active {
                width: 1.5rem;
                height: 1.5rem;
                margin: -0.75rem;
                border: 0.5rem solid #ffffff;
              }
            }
            @for $i from 1 to 10 {
              li:nth-child(#{$i}) {
                transform: rotate(#{($i - 3) * 40}deg);
              }
            }
          }
          .service-content__item {
            position: relative;
            z-index: 1;
            .item {
              .service-photo {
                position: relative;
                width: 80%;
                height: 26rem;
                left: -40%;
                overflow: hidden;
                display: none;
                opacity: 0;
                img {
                  position: absolute;
                  width: 180%;
                  height: auto;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  transition: 2s;
                  transition-timing-function: ease-in;
                }
                &.activate {
                  animation: fadeIn 0.4s forwards;
                  display: block;
                }
              }
              .service-name {
                position: absolute;
                width: 21rem;
                top: 9rem;
                display: none;
                opacity: 0;
                h1 {
                  color: #ffffff;
                  transition: 2.25s;
                  text-align: center;
                  font-size: 2rem;
                  font-weight: bold;
                  padding: 0.5rem;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                }
                &.activate {
                  animation: fadeIn 0.75s forwards;
                  display: block;
                }
              }
            }
          }
        }
      }
      .service.animation {
        transform: translateX(0);
      }
      .client {
        flex: 1;
        display: flex;
        align-items: center;
        transform: translateX(200%);
        transition: 0.75s;
        transition-timing-function: ease-in;
        .client-content {
          width: 100%;
          img {
            width: 100%;
          }
        }
      }
      .client.animation {
        transform: translateX(0);
      }
    }
  }
}
