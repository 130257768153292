html,
body {
  margin: 0;
  padding: 0;
}

.contents {
    display: flex;
    justify-content: center;
    &::-webkit-scrollbar {
        display: none;
    }
    .contents-container {
        width: 80vw;
        position: relative;
        .contents-title {
            margin: 64px 0 52px 0;
            font-size: 20px;
        }
        .contents-detail {
            margin-bottom: 64px;
            text-align: justify;
            line-height: 1.7em;
            h2 {
                margin-bottom: 0px;
            }
            h3 {
                margin-top: 8px;
                font-weight: 500;
            }
            h4 {
                font-weight: 300;
            }
        }
        &::-webkit-scrollbar {
            display: none;
        }
    }      
}

