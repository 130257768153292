@import '../../utils.scss';

header {
  background-color: #000000;
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: visible;
  position: sticky;
  transition: ease-in-out 0.2s;
  z-index: 1;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    max-width: 1140px;
    @include media('<large') {
      max-width: 960px;
    }
    @include media('<medium') {
      max-width: calc(100% - 64px);
    }
    @include media('<small') {
      max-width: calc(100% - 32px);
    }

    .header-logo {
      height: 30px;
      img {
        height: 100%;
      }
      @include media('<medium') {
        height: 25px;
      }
    }
    .header-menu {
      color: #ffffff;
      @include media('<medium') {
        color: #7c7c7c;
        position: fixed;
        top: 0;
        left: -100%;
        overflow-y: auto;
        background: #fff;
        width: 200px;
        height: 100%;
        -webkit-box-shadow: 0 0 36px 0 rgba(22, 25, 28, 0.17);
        box-shadow: 0 0 36px 0 rgba(22, 25, 28, 0.17);
        padding: 20px 30px;
        -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
        transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
        ul {
          padding: 0;
          li {
            position: relative;
            display: block;
            width: 100%;
            height: auto;
            margin: 0;
            border-bottom: 1px solid #e3e6e9;
            padding: 14px 0;
            text-align: left;
            a {
              color: inherit;
              text-decoration: inherit;
            }
          }
        }
      }
      @include media('>medium') {
        ul {
          display: flex;
          flex-direction: row;
          list-style: none;
          li {
            position: relative;
            display: block;
            width: auto;
            height: auto;
            margin: 0 0 0 20px;
            border-bottom: 0px solid #e3e6e9;
            padding: 14px 0;
            padding-right: 14px;
            text-align: left;
            padding: 0;
            font-weight: 300;
            cursor: pointer;
            letter-spacing: -0.2px;
            a {
              color: inherit;
              cursor: pointer;
              text-decoration: inherit;
            }
          }
        }
      }
    }
    .header-menu.show {
      left: 0;
    }
    button.header-toggle {
      position: relative;
      cursor: pointer;
      display: inline-block;
      width: 28px;
      height: 28px;
      margin-left: auto;
      cursor: pointer;
      background: transparent;
      box-shadow: none;
      border: 0;
      padding: 0;
      -webkit-tap-highlight-color: transparent;
      &:focus {
        outline: none;
        box-shadow: none;
      }

      span {
        background: transparent;
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 100%;
        height: 2px;
        border-radius: 2px;
        -webkit-transition: linear 0.1s;
        transition: linear 0.1s;
        background: #fff;
        &:before {
          background: #fff;
          top: -7px;
          content: '';
          position: absolute;
          left: 0;
          width: 100%;
          height: 2px;
          border-radius: 2px;
          -webkit-transition: linear 0.1s;
          transition: linear 0.1s;
        }
        &:after {
          background: #fff;
          bottom: -7px;
          content: '';
          position: absolute;
          left: 0;
          width: 100%;
          height: 2px;
          border-radius: 2px;
          -webkit-transition: linear 0.1s;
          transition: linear 0.1s;
        }
      }
      @include media('>medium') {
        display: none;
      }
    }
    .header-toggle.toggle-close {
      span {
        background: transparent;
        &:before {
          top: 0;
          bottom: 0;
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
        &:after {
          top: 0;
          bottom: 0;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
  }
}
header.hide {
  transform: translateY(-100%);
}
