@import '~include-media/dist/include-media';

$breakpoints: (
  small: 376px,
  medium: 992px,
  large: 1200px,
);

html {
  font-family: 'Poppins', sans-serif;
}
