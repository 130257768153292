@import '../../utils.scss';
@keyframes orbit {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.section {
  position: fixed;
  top: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &.activate {
    display: none;
  }
  .container {
    background-color: #ffffff;
    border-radius: 0.375em;
    width: calc(100% - 32px);
    max-height: calc(100% - 32px);
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    @include media('>medium') {
      width: 992px;
    }
    .content {
      padding: 24px;
      @include media('>medium') {
        padding: 3rem;
      }
      .header {
        display: flex;
        justify-content: space-between;
        .logo {
          margin-bottom: 18px;
          img {
            width: 6rem;
            @include media('>medium') {
              width: 8rem;
            }
          }
        }
        .close {
          cursor: pointer;
          position: relative;
          img {
            @include media('>medium') {
              width: 2rem;
            }
          }
        }
        .back {
          cursor: pointer;
          position: relative;
          img {
            width: 6rem;
            @include media('>medium') {
              width: 9rem;
            }
          }
        }
      }
      .loading-image {
        width: 4rem;
        height: 4rem;
        margin: 5rem auto;
        img {
          width: 100%;
          height: 100%;
          animation: orbit 2s linear infinite;
        }
      }
      .body {
        @include media('>medium') {
          display: flex;
          margin: 3rem auto;
        }
        .text-content {
          margin-bottom: 3rem;
          &.project-quote {
            margin-bottom: 1rem;
            @include media('>medium') {
              margin-right: 3rem;
            }
          }
          h1 {
            font-size: 1.25rem;
            color: #2c2c2c;
            line-height: 1.5;
            letter-spacing: 0;
            margin-top: 0;
            color: inherit;
            font-weight: lighter;
            @include media('>medium') {
              font-size: 4rem;
            }
            &.project-quote {
              font-size: 2rem;
              @include media('>medium') {
                font-size: 4.5rem;
                line-height: 1.5;
              }
            }
          }
        }
        .input-content {
          label {
            font-size: 0.9rem;
            color: #2c2c2c;
            @include media('>medium') {
              font-size: 1rem;
            }
            input {
              margin: 12px 0 12px 0;
              font-size: 0.8rem;
              font-weight: lighter;
              width: 100%;
              border: none;
              border-bottom: 1px solid rgba(112, 112, 112, 0.3);
              line-height: 1;
              -webkit-transition: all 0.3s ease-in-out;
              -moz-transition: all 0.3s ease-in-out;
              -o-transition: all 0.3s ease-in-out;
              transition: all 0.3s ease-in-out;
              box-sizing: border-box;
            }
            textarea {
              margin: 12px 0 12px 0;
              font-size: 0.8rem;
              font-weight: lighter;
              width: 100%;
              border: none;
              border-bottom: 1px solid rgba(112, 112, 112, 0.3);
              line-height: 2;
              -webkit-transition: all 0.3s ease-in-out;
              -moz-transition: all 0.3s ease-in-out;
              -o-transition: all 0.3s ease-in-out;
              transition: all 0.3s ease-in-out;
              box-sizing: border-box;
            }
            &.select-title {
            }
          }
          .purpose-select {
            display: grid;
            grid-template-columns: auto auto;
            margin-bottom: 24px;
            label {
              display: flex;
              font-size: 0.8rem;
              align-items: center;
              @include media('>medium') {
                font-size: 1.1rem;
                cursor: pointer;
              }
              span {
              }
              input {
                width: 1rem;
                height: 1rem;
                margin: 0.5rem;
                border-radius: 100%;
                background-color: #404040;
                border: none;
                position: relative;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                color: #404040;
                &:checked {
                  color: #404040;
                  background-color: #404040;
                }
              }
            }
          }
          .time-select {
            display: grid;
            grid-template-columns: auto auto;
            margin-bottom: 16px;
            @include media('>medium') {
              grid-template-columns: auto;
            }
            label {
              display: flex;
              font-size: 0.8rem;
              align-items: center;
              @include media('>medium') {
                font-size: 1.1rem;
                margin: 0.25rem;
                cursor: pointer;
              }
              span {
              }
              input {
                width: 1rem;
                height: 1rem;
                margin: 0.5rem;
                border-radius: 100%;
                border: none;
                position: relative;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                background-color: #404040;
                color: #404040;
                &:checked {
                  color: #404040;
                  background-color: #404040;
                }
              }
            }
          }
        }
      }
      .select-nav {
        display: flex;
        justify-content: space-between;
        button {
          cursor: pointer;
          width: 48%;
          background-color: #404040;
          color: #ffffff;
          height: 4rem;
          border-radius: 0.375em;
          background-color: #404040;
          color: #fff;
          font-size: 0.75rem;
          line-height: 1.5;
          letter-spacing: 0.3em;
          font-weight: 400;
          border: 3px solid #404040;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
          &:focus {
            outline: none;
            box-shadow: none;
          }
          &.project-quote {
            margin-top: 16px;
            width: 90%;
            font-size: 0.9rem;
            text-align: center;
          }
          &.disabled {
            cursor: default;
            background-color: #e3e4e4;
            border: 3px solid #e3e4e4;
          }
        }
        &.project-quote {
          justify-content: center;
        }
      }
    }
  }
}

input,
textarea {
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
}
