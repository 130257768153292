@import '../../utils.scss';

.section {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../../assets/reputation_background.jpg');
  position: relative;
  background-position: center center;
  background-attachment: scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  .bg-black {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    .container {
      padding: 120px 30px;
      max-width: 1140px;
      margin: 0 auto;
      .content {
        color: white;
        @include media('>medium') {
          display: flex;
          align-items: center;
        }
        .text-content {
          @include media('>medium') {
            display: flex;
            flex-direction: column;
          }
          h1 {
            transform: translateX(-200%);
            transition: ease-in 1s;
            font-size: 2.66em;
            font-weight: 300;
            margin: 0 0 10px;
            letter-spacing: -0.3px;
            @include media('>medium') {
              font-size: 5em;
            }
            &.animation {
              transform: translateX(0);
            }
          }
          .markus {
            opacity: 0;
            transition: ease-in 2s;
            p {
              line-height: 1.75;
              text-align: left;
              color: rgba(255, 255, 255, 0.7);
              @include media('<small') {
                font-size: 14px;
              }
              @include media('>medium') {
                font-size: 1.25rem;
                max-width: 70%;
                font-weight: 100;
                line-height: 1.75;
                letter-spacing: 0;
                color: rgba(255, 255, 255, 0.7);
              }
            }
            .client-content {
              display: flex;
              align-items: center;
              margin-top: 40px;
              img {
                width: 100px;
                height: 100px;
                margin-right: 40px;
                @include media('<small') {
                  width: 80px;
                  height: 80px;
                  margin-right: 20px;
                }
              }
              p {
                font-size: 1rem;
                font-weight: bold;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                color: #ffffff;
                @include media('<small') {
                  font-size: 1.15rem;
                }
              }
            }
            &.animation {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
