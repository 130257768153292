@import '../../utils.scss';

li {
  cursor: pointer;
}

.bar {
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  opacity: 1;
  transition: ease-in 0.5s;
  padding: 1rem 0rem;
  background-color: #000000;
  top: 0;
  z-index: 999;
  @include media('>medium') {
    height: 0;
    width: fit-content;
    position: fixed;
    top: 12rem;
    z-index: 9999;
    background-color: transparent;
  }
  .container {
    @include media('<=medium') {
      display: flex;
      margin: 0;
      .mobile {
        display: flex;
        span {
          display: flex;

          color: #d3d3d3;
          h3 {
            margin: 0 auto;
            margin-right: 12px;
            z-index: 2;
            font-size: 0.75rem;
            letter-spacing: 0.2em;
            font-weight: 400;
            @include media('>medium') {
              font-weight: 600;
            }
          }
          h4 {
            margin: 0 auto;
            z-index: 2;
            font-size: 0.75rem;
            letter-spacing: 0.2em;
            font-weight: 300;
            &::after {
              content: '';
              width: 3rem;
              display: inline-block;
              border: 0.2px solid white;
              vertical-align: middle;
            }
          }
        }
      }
      .desktop {
        display: none;
      }
    }
    @include media('>medium') {
      list-style: none;
      padding-left: 18px;
      li.desktop {
        &::before {
          content: '';
          display: block;
          width: 2px;
          height: 10rem;
          background-color: #777;
          margin: 0 0 2rem 0.7rem;
        }
        span {
          h3 {
            margin: 0;
            color: #707070;
            z-index: 2;
            font-size: 1.5rem;
            writing-mode: vertical-lr;
            letter-spacing: 0.2em;

            transform: rotateX(180deg) rotateY(180deg);
          }
          h4 {
            margin: 0 auto;
            color: #707070;
            z-index: 2;
            font-size: 1.5rem;
            margin-bottom: 16px;
            writing-mode: vertical-lr;
            letter-spacing: 0.2em;
            font-weight: 300;
            transform: rotateX(180deg) rotateY(180deg);
          }
        }
      }
      .mobile {
        display: none;
      }
    }
  }
  &.hide {
    height: 0;
    opacity: 0;
  }
}
