@import '../../utils.scss';

.section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url('../../assets/main(mobile).jpg');
  position: relative;
  background-position: center center;
  background-attachment: scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  @include media('>large') {
    height: 100vh;
  }
  .container {
    width: 100%;
    height: 100%;
    max-width: 1140px;
    display: flex;
    align-items: center;

    @include media('<large') {
      max-width: 960px;
    }
    @include media('<medium') {
      max-width: calc(100% - 64px);
    }
    @include media('<small') {
      max-width: calc(100% - 32px);
    }

    .introduction {
      display: flex;
      justify-content: flex-start;
      h1 {
        color: #ffffff;
        font-size: 2.6rem;
        font-weight: 300;
        @include media('>medium') {
          font-size: 5.8rem;
          font-weight: lighter;
        }
      }
    }
  }
}
