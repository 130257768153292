@import '../../utils.scss';

.section {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../../assets/ContactUs.jpg');
  position: relative;
  background-position: center center;
  background-attachment: scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  .bg-black {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    .container {
      padding: 120px 30px;
      max-width: 1140px;
      margin: 0 auto;
      .content {
        color: white;
        @include media('>medium') {
          display: flex;
          align-items: center;
        }
        .text-content {
          transform: translateX(-200%);
          transition: 1s;
          transition-timing-function: ease-in;
          @include media('>medium') {
            display: flex;
            flex-direction: column;
          }
          h1 {
            font-size: 2.66em;
            font-weight: 300;
            line-height: 1.5;
            margin: 0 0 10px;
            letter-spacing: -0.3px;
            @include media('>medium') {
              font-size: 5rem;
              font-weight: lighter;
              max-width: 80%;
              line-height: 1.2;
            }
          }
          p {
            line-height: 1.75;
            text-align: left;
            color: rgba(255, 255, 255, 0.7);
            @include media('<small') {
              font-size: 16px;
            }
            @include media('>medium') {
              font-size: 1.25em;
              max-width: 70%;
              letter-spacing: 1px;
              font-weight: 300;
            }
          }
        }
        .text-content.animation {
          transform: translateX(0);
        }
        button {
          background: transparent;
          border-color: rgba(255, 255, 255, 0.3);
          color: #fff;
          cursor: pointer;
          border-radius: 0.375em;
          padding: 18px 50px;
          position: relative;
          display: inline-block;
          overflow: hidden;
          font: 400 13px 'Poppins', sans-serif;
          letter-spacing: 1px;
          text-decoration: none;
          box-sizing: border-box;
          border: 0.3px solid rgba(255, 255, 255, 0.3);
          transition: ease-out 0.12s;
          @include media('>medium') {
            height: 100%;
            width: 80%;
            padding: 24px 0px;
          }
          &:hover {
            background-color: rgba(255, 255, 255, 0.3);
          }
          a {
            text-decoration: none;
            color: inherit;
            width: 100%;
            height: 100%;
            padding: 20px;
            @include media('>medium') {
              padding: 28px 80px;
            }
          }
        }
      }
    }
  }
}
