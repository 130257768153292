@import '../../utils.scss';

.section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
  @include media('>medium') {
    padding: 80px 0 40px 0;
  }
  .container {
    width: 100%;
    height: 100%;
    max-width: 1140px;
    display: flex;
    align-items: center;

    @include media('<large') {
      max-width: 960px;
    }
    @include media('<medium') {
      max-width: calc(100% - 64px);
    }
    @include media('<small') {
      max-width: calc(100% - 32px);
    }
    .content {
      width: 100%;
      @include media('>medium') {
        display: flex;
      }
      .image-container {
        transform: translateX(200%);
        transition: ease-in 1s;
        width: 100%;
        margin-bottom: 30px;
        img {
          width: 100%;
        }
        &.animation {
          transform: translateX(0);
        }
      }
      .text-container {
        transform: translateX(-200%);
        transition: ease-in 1s;
        @include media('>medium') {
          margin: 0px 60px 50px 30px;
          max-width: 45%;
        }
        h2 {
          font-size: 2.13em;
          line-height: 1.625;
          font-weight: 300;
          margin: 0 0 10px;
          letter-spacing: -0.3px;
          color: #131517;
          @include media('>medium') {
            font-size: 6rem;
            line-height: 1.2;
            letter-spacing: 0;
            margin-top: 0;
            color: inherit;
            font-weight: 300;
          }
        }
        h1 {
          font-size: 2.13em;
          line-height: 1.625;
          font-weight: 300;
          margin: 0 0 10px;
          letter-spacing: -0.3px;
          color: #131517;
          @include media('>medium') {
            font-size: 5rem;
            line-height: 1.2;
            letter-spacing: 0;
            margin-top: 0;
            color: inherit;
            font-weight: 400;
          }
        }

        p {
          color: #7c7c7c;
          font-size: 15px;
          font-weight: 400;
          letter-spacing: 0;
          font-size: 14px;
          line-height: 1.75;
          max-width: 85%;
          @include media('>medium') {
            font-size: 1.25rem;
            line-height: 1.75;
            letter-spacing: 0;
            font-weight: 300;
            color: #2c2c2c;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
        }
        &.animation {
          transform: translateX(0);
        }
      }
    }
  }
}
