@import '../../utils.scss';

.section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
  margin: 0 12px;
  @include media('>=medium') {
    display: none;
  }
  .container {
    width: 100%;
    height: 100%;
    max-width: 1140px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media('<large') {
      max-width: 960px;
    }
    @include media('<medium') {
      max-width: calc(100% - 64px);
    }
    @include media('<small') {
      max-width: calc(100% - 32px);
    }
    .top-content {
      width: 100%;
      margin-bottom: 60px;
      transform: translateX(-200%);
      transition: ease-in 1s;
      .image-container {
        width: 100%;
        margin-bottom: 30px;
        img {
          width: 100%;
        }
        .background {
          position: absolute;
        }
      }
      h2 {
        font-size: 2.13em;
        line-height: 1.625;
        font-weight: 300;
        margin: 0 0 10px;
        letter-spacing: -0.3px;
        color: #131517;
      }
      p {
        color: #7c7c7c;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0;
        font-size: 14px;
        line-height: 1.75;
        @include media('>medium') {
          max-width: 75%;
        }
      }
      &.animation {
        transform: translateX(0%);
      }
    }
    .sub-content {
      width: 100%;
      transform: translateX(100%);
      transition: ease-in 1s;
      @include media('>medium') {
        display: grid;
        grid-gap: 30px;
        grid-template-columns: auto auto auto;
      }
      .item {
        margin-bottom: 48px;
        .icon {
          margin-bottom: 20px;
        }
        h5 {
          margin: 0 0 10px;
          letter-spacing: -0.3px;
          color: #131517;
          font-size: 1.33em;
          line-height: 1.8;
          font-weight: 400;
        }
        p {
          line-height: 1.75;
          color: #7c7c7c;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0;
        }
      }
      &.animation {
        transform: translateX(0%);
      }
    }
  }
}
