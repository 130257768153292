@import '../../utils.scss';

.move-top {
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: ease-in 0.5s;
  top: calc(100vh - 8rem);
  z-index: 999;
  @include media('>medium') {
    top: calc(100vh - 8rem);
    margin: -3rem;
    width: 100%;
    height: 100%;
    z-index: 999;
  }
  .container {
    position: absolute;
    right: 0;
    a {
      cursor: pointer;
      img {
        padding: 1rem;
        width: 4rem;
        height: 4rem;
      }
    }
    @include media('>medium') {
      list-style: none;
      position: absolute;
      right: 0;
      a {
        cursor: pointer;
        img {
          width: 5rem;
          height: 5rem;
        }
      }
    }
  }
}
